(($) => {
	$(document).ready(() => {
		$("#go_to_instructors").on("click", function (e) {
			e.preventDefault();
			$("#instructors").get(0).scrollIntoView({
				behavior: "smooth",
			});
		});

		window.clear_search = () => {
			$("#keyword").val("");
			quick_search();
		};

		$(document).ready(() => {
			$("#scrollable_results").on("scroll", function (e) {
				let elem = $(e.currentTarget);
				if (elem[0].scrollHeight < 50) {
					$("#scrolldownindicator").addClass("hidden");
					$("#scrollupindicator").addClass("hidden");
				} else if (
					elem[0].scrollHeight - elem.scrollTop() <=
					Math.ceil(elem.outerHeight())
				) {
					$("#scrolldownindicator").addClass("hidden");
					$("#scrollupindicator").removeClass("hidden");
				} else {
					$("#scrolldownindicator").removeClass("hidden");
					$("#scrollupindicator").addClass("hidden");
				}
			});
		});

		$("#keyword").focus();

		$("#scrolldownindicator").click((e) => {
			$(".home_search__list").animate(
				{
					scrollTop: "+=150", // Scroll 50px down on each button click
				},
				"slow"
			);
		});

		$("#scrollupindicator").click((e) => {
			$(".home_search__list").animate(
				{
					scrollTop: "0", // Scroll 50px down on each button click
				},
				"slow"
			);
		});

		$(".newsletter__form").submit(function (event) {
			var email = $('input[name="cf_0"]').val();
			var gizlilik = $('input[name="gizlilik"]');
			$("#msg").html("");
			if (email == "") {
				event.preventDefault();
				$("#msg").add("hidden");
				$("#msg").html("Lütfen e-posta alanını doldurunuz<br/>");
			} else {
				$("#msg").addClass("hidden");
			}

			if (!gizlilik.is(":checked")) {
				event.preventDefault();
				$("#msg").removeClass("hidden");
				$("#msg").append("Lütfen gizlilik koşullarını kabul ediniz.<br/>");
			} else {
				$("#msg").addClass("hidden");
			}
			if (gizlilik.is(":checked") && email != "") {
				$("#msg").addClass("hidden");
				$("#msg").html("");

				return true;
			}
		});
	});
})(jQuery);
